.gr-select-error {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #db0020;
}

.gr-select[multiple]:disabled option {
  cursor: default;
}

.gr-meta {
  font-size: 12px;
  color: #6a7070;
}

.gr-meta {
  line-height: 12px;
}

.gr-label,
.gr-meta {
  font-size: 12px;
  color: #6a7070;
}

.gr-label {
  line-height: 16px;
  display: block;
  margin-bottom: 4px;
}

.gr-select-container {
  position: relative;
}

.gr-select[multiple] {
  height: auto;
}

.gr-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  margin: 4px 0;
  padding: 0 42px 0 12px;
  height: 36px;
  color: #252525;
  border: 1px solid #c7c7c7;
  background: #fff;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}

select {
  text-transform: none;
}

select {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.gr-select[multiple] option {
  cursor: pointer;
}

.gr-select-container.error {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.gr-select-container.error {
  border-color: #db0020;
}
.gr-select-container {
  position: relative;
}

.gr-select-container.error + .error-state {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.gr-select-container.error + .error-state svg {
  fill: #db0020;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.gr-select-container.error + .error-state svg {
  fill: #db0020;
}

.gr-select-container.error + .error-state span {
  margin-left: 8px;
  color: #db0020;
}

.gr-input + .error-state,
.gr-textarea + .error-state,
.gr-checkbox + .error-state,
.gr-radio + .error-state,
.gr-select-container + .error-state,
.gr-select-container + .error-state {
  display: none;
}

.gr-select:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #1977d4;
}

.gr-select-container svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 9px);
  fill: #6A7070;
}