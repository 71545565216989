/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

// Spacing utils

@each $key, $value in $spacers {
  .p-la-mb-#{$key},
  .p-la-my-#{$key} {
    margin-bottom: $value !important;
  }

  .p-la-ml-#{$key},
  .p-la-mx-#{$key} {
    margin-left: $value !important;
  }

  .p-la-mr-#{$key},
  .p-la-mx-#{$key} {
    margin-right: $value !important;
  }

  .p-la-mt-#{$key},
  .p-la-my-#{$key} {
    margin-top: $value !important;
  }

  .p-la-pb-#{$key},
  .p-la-py-#{$key} {
    padding-bottom: $value !important;
  }

  .p-la-pl-#{$key},
  .p-la-px-#{$key} {
    padding-left: $value !important;
  }

  .p-la-pr-#{$key},
  .p-la-px-#{$key} {
    padding-right: $value !important;
  }

  .p-la-pt-#{$key},
  .p-la-py-#{$key} {
    padding-top: $value !important;
  }
}

// Font utils

.p-la-fw {
  &-semibold {
    font-weight: 600;
  }
}

// Position utils

.p-la-pos {
  &-static {
    position: static !important;
  }

  &-relative {
    position: relative !important;
  }

  &-absolute {
    position: absolute !important;
  }

  &-fixed {
    position: fixed !important;
  }
}

// Text alignment utils

.p-la-text {
  &-center {
    text-align: center;
  }

  &-justify {
    text-align: justify;
  }

  &-left {
    text-align: left;
  }

  &-right {
    text-align: right;
  }
}

.p-la-float {
  &-left {
    float: left;
  }

  &-none {
    float: none;
  }

  &-right {
    float: right;
  }
}

.p-la-fixed {
  &-top {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1030;
  }
}
