/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

.p-la-tabs {
  .p-la-nav-tabs {
    align-items: flex-end;
    background: $white;
  }

  .p-la-nav-item {
    height: 2.75rem;
  }

  .p-la-tab-pane {
    display: none;
    padding: 2rem 1.5rem;

    &.active {
      display: block;
    }
  }
}
