/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

//== Colors

$charcoal: #252525;
$medium-gray: #6a7070;

$steel: #a9a9a9;
$concrete: #c7c7c7;
$alto: #d9d9d9;
$moonlight: #e9e9e9;

$stainless: #eee;
$white-gray: #f5f5f5;

$pearson-blue: #005d83;
$sunshine-orange: #ffb81c;
$sunshine-yellow: #ffcb4e;

$alert-red: #db0020;
$confirm-green: #038238;
$notification-pink: #da0474;

$white: #fff;
$black: #000;
$transparent: transparent;

$primary: $pearson-blue;
$secondary: $sunshine-yellow;

$disabled-bg: $alto;
$disabled-color: $medium-gray;

$focus-outline: #1977d4;
$focus-outline-light: #368de4;
$focus-outline-dark: #64b0fb;

//== Body

$body-bg: $white;
$body-color: $charcoal;

//== Spacing

$spacer: 0.5rem;
$spacers: map-merge(
  (
    auto: auto,
    0: 0,
    4: $spacer / 2,
    8: $spacer,
    12: $spacer * 1.5,
    16: $spacer * 2,
    20: $spacer * 2.5,
    24: $spacer * 3,
    28: $spacer * 3.5,
    32: $spacer * 4,
    36: $spacer * 4.5,
    40: $spacer * 5,
    48: $spacer * 6,
    56: $spacer * 7,
    64: $spacer * 8,
    72: $spacer * 9,
    80: $spacer * 10,
  ),
  ()
);

//== Typography

$font-family-text: 'Open Sans', sans-serif;
$font-family-headings: 'Open Sans', serif;

$font-size-base: 0.875rem;
$font-size-lg: 1rem;
$font-size-sm: 0.75rem;

$font-size-h1: 1.5rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1.125rem;
$font-size-h4: 1rem;
$font-size-h5: 0.875rem;
$font-size-h6: 0.75rem;

$font-weight-normal: 400;
$font-weight-semibold: 600;

$line-height-base: 1.4285; // 20/14
$line-height-lg: 1.5; // 24/16
$line-height-sm: 1.3333; // 16/12

$line-height-h1: 1.1666; // 28/24
$line-height-h2: 1.3; // 26/20
$line-height-h3: $line-height-lg;
$line-height-h4: 1.25; // 20/16
$line-height-h5: 1.2857; // 18/14
$line-height-h6: 1.3333; // 16/12

$link-color: $primary;
$link-hover-color: #005a70;

//== Component

$component-bg: $white;
$component-border-radius: 0.25rem;

$component-padding-y: map-get(
  $map: $spacers,
  $key: 16,
);
$component-padding-x: map-get(
  $map: $spacers,
  $key: 16,
);
