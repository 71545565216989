//
// Breakpoints
// --------------------------------------------------

$screen-mt-min: 728px; // Medium tablet
$screen-lt-min: 888px; // Large tablet
$screen-sd-min: 984px; // Small desktop
$screen-md-min: 1080px; // Medium desktop
$screen-ld-min: 1176px; // Large desktop

@mixin medium-tab {
  @media (min-width: #{$screen-mt-min}) {
    @content;
  }
}

@mixin large-tab {
  @media (min-width: #{$screen-lt-min}) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: #{$screen-sd-min}) {
    @content;
  }
}

@mixin medium-desktop {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$screen-ld-min}) {
    @content;
  }
}
