/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

// Button variants
@mixin button-variant($background, $color, $hover-background: darken($background, 28%)) {
  background-color: $background;
  border-color: $background;
  color: $color;

  &:focus,
  &.focus {
    background-color: $hover-background;
    color: $color;
  }

  &:hover {
    background-color: $hover-background;
    border-color: $hover-background;
    color: $color;
  }

  &.disabled,
  &:disabled {
    background: $disabled-bg;
    border-color: $disabled-bg;
    color: $disabled-color;
    opacity: 1;
    pointer-events: none;
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  border-radius: $border-radius;
  font-size: $font-size;
  line-height: $line-height;
  padding: $padding-y $padding-x;
}
