/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

.p-la-form-group {
  margin-bottom: 32px;
}

.p-la-form-label {
  color: $medium-gray;
  display: block;
  font-size: $font-size-sm;
  line-height: 1.333;
  margin-bottom: 4px;
}

.p-la-form-error-label {
  color: $alert-red;
  display: block;
  font-size: $font-size-sm;
  line-height: 1.333;
  margin-bottom: 4px;
}

.p-la-form-control {
  background: $white;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  color: $charcoal;
  display: block;
  font-size: $font-size-base;
  height: 36px;
  margin: 4px 0;
  max-width: 100%;
  padding: 0 12px;
  width: 100%;

  &:focus {
    box-shadow: 0 0 0 2px $white, 0 0 0 4px #1977d4;
    outline: 0;
  }

  &.p-la-is-invalid {
    border: 1px solid $alert-red;
  }

  &.disabled,
  &[disabled] {
    background: $moonlight;
    color: $concrete;

    &::placeholder {
      color: $concrete;
    }
  }

  &[type='number'] {
    -webkit-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &::placeholder {
    color: $charcoal;
    opacity: 1;
  }
}

textarea {
  &.p-la-form-control {
    min-height: 84px;
    padding-bottom: 8px;
    padding-top: 8px;
  }
}

.p-la-form-info {
  @extend .p-la-form-label;
  color: $charcoal;
  margin-top: 4px;

  &.p-la-form-info-muted {
    color: $medium-gray;
  }
}

.p-la-form-text {
  color: $charcoal;
  display: block;
  font-size: $font-size-sm;
  line-height: 1.5;
  margin-bottom: 8px;
}

.p-la-form-check {
  line-height: $line-height-base;
  position: relative;

  .p-la-custom-check {
    display: inline-flex;
    position: absolute;
    left: 0;
    top: 0;

    > svg {
      fill: $concrete;
      height: 20px;
      width: 20px;

      &.p-la-custom-checked {
        fill: $primary;
      }
    }

    + span {
      display: inline-block;
    }
  }

  + .p-la-form-check {
    margin-top: 16px;
  }

  > label {
    cursor: pointer;
    font-size: $font-size-base;
    margin-bottom: 0;
    padding-left: 32px;
    position: relative;
  }

  > input[type='checkbox'],
  > input[type='radio'] {
    opacity: 0;

    &:disabled {
      + label {
        svg {
          background: $moonlight;
          fill: $concrete;
        }
      }
    }

    &:focus {
      + label {
        .p-la-custom-check {
          outline: 0;
        }
      }
    }
  }

  > input[type='checkbox'] {
    &:disabled {
      + label {
        svg {
          border-radius: 4px;
        }
      }
    }

    &:focus {
      + label {
        > .p-la-custom-check {
          border-radius: 2px;
          box-shadow: 0 0 0 1px $white, 0 0 0 3px #1977d4;
        }
      }
    }
  }

  > input[type='radio'] {
    &:disabled {
      + label {
        svg {
          border-radius: 10px;
        }
      }
    }

    &:focus {
      + label {
        > .p-la-custom-check {
          border-radius: 10px;
          box-shadow: 0 0 0 2px $white, 0 0 0 4px #1977d4;
        }
      }
    }
  }

  &.p-la-form-check-right {
    > label {
      padding-left: 0;
      padding-right: 32px;
    }

    .p-la-custom-check {
      left: auto;
      right: 0;
    }
  }
}
