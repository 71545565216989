/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

.p-wsol-btn {
  @include button-size(7px, 24px, 16px, 1.25, 36px);
  background: transparent;
  border: 1px solid $medium-gray;
  color: $medium-gray;
  font-weight: $font-weight-semibold;
  min-width: 152px;
  position: relative;
  text-decoration: none;

  &:hover {
    border-color: $charcoal;
    color: $charcoal;
  }

  &:focus {
    outline: 0 none;
    box-shadow: 0 0 0 2px $white, 0 0 0 4px $focus-outline-light;
  }

  &.disabled,
  &[disabled] {
    background: $moonlight;
    border-color: $moonlight;
    color: $medium-gray;

    &:focus {
      box-shadow: none;
    }
  }
}

.p-wsol-btn-dark {
  @include button-size(7px, 24px, 16px, 1.25, 36px);
  background: rgba(255, 255, 255, 0.88);
  color: rgba(37, 37, 37, 0.9);
  border: 1px solid;
  font-weight: $font-weight-semibold;
  min-width: 152px;
  position: relative;
  text-decoration: none;

  &:hover {
    background: rgba(255, 255, 255, 1);
    color: rgba(37, 37, 37, 0.9);
    border-color: $white;
  }

  &:focus {
    background: rgba(255, 255, 255, 1);
    outline: 0 none;
    box-shadow: 0 0 0 2px #047a9c, 0 0 0 4px $focus-outline-dark;
  }

  &.disabled,
  &[disabled] {
    background: rgba(217, 217, 217, 0.6);
    color: rgba(106, 112, 112, 1);
    border-color: $moonlight;
    &:focus {
      box-shadow: none;
    }
  }
}

.p-wsol-btn-sm {
  @include button-size(6px, 24px, 14px, 1.2857, 32px);
  min-width: 128px;
}

.p-wsol-btn-md {
  @include button-size(px, 24px, 16px, 1.25, 36px);
  min-width: 152px;
}

.p-wsol-btn-lg {
  @include button-size(9px, 24px, 16px, 1.25, 40px);
}

.p-wsol-btn-primary {
  @include button-variant($primary, $white, #047a9c);
}

.p-wsol-btn-attention {
  @include button-variant($secondary, $charcoal, #ffe15c);
}

.p-wsol-btn-icon {
  @include button-variant($transparent, $medium-gray, $transparent);
  border-radius: 4px;
  min-width: 0;
  padding: 0 16px;

  svg {
    fill: $medium-gray;
  }
}
