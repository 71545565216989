/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

.p-la-nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.p-la-nav-tabs {
  .p-la-nav-item {
    color: $medium-gray;
    cursor: pointer;
    min-width: 8.125rem;
    padding: 0.75rem 1.5rem;
    text-align: center;
    text-decoration: none;

    &.active {
      border-bottom: 2px solid $charcoal;
      color: $charcoal;
      font-weight: $font-weight-semibold;
    }

    &.disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}
