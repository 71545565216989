/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

.p-la-modal-overlay {
  background: #252525;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  opacity: 0.6;
  z-index: 9999;
}

.p-la-modal {
  background: #fff;
  border-radius: 2px;
  filter: blur(0);
  left: 50%;
  margin: -150px 0 0 -300px;
  opacity: 1;
  position: fixed;
  top: 50%;
  transform: scale(1);
  transition: 1.1s ease-out;
  visibility: visible;
  width: 600px;
  z-index: 9999;

  .p-la-modal-header {
    display: flex;
    padding: 40px 40px 0;
  }

  .p-la-modal-body {
    display: flex;
    padding: 8px 40px;
  }

  .p-la-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0 40px 40px;

    .p-la-btn {
      + .p-la-btn {
        margin-left: 16px;
      }
    }
  }
}
