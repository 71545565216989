/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

.p-la-editor {
  .p-la-editor-controls {
    border: 1px solid $concrete;
    border-radius: 4px 4px 0 0;
    display: flex;
    padding: 4px 8px;

    + .DraftEditor-root {
      border-top: 0 none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    > :first-child {
      padding-left: 0;
    }

    > div {
      + div {
        border-left: 1px solid $concrete;
      }
    }
  }

  .DraftEditor-root {
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    width: 100%;
  }

  .public-DraftEditor-content {
    line-height: $line-height-base;
    min-height: 120px;
    max-width: 100%;
    padding: 12px;
  }

  .p-la-icon {
    border-radius: 2px;
    height: 30px;
    width: 30px;

    &:focus {
      box-shadow: 0 0 1px 2px $focus-outline;
    }
  }
}

.p-la-editor-controls-inline,
.p-la-editor-controls-block {
  padding: 0 12px;
}

.p-la-editor-link-prompt {
  background: #fff;
  border: 1px solid $concrete;
  padding: 12px;
  position: absolute;
  z-index: 1;
}
