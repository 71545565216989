/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap');

.h1 {
  color: $body-color;
  font-size: $font-size-h1;
  font-weight: $font-weight-normal;
  line-height: $line-height-h1;
}

.h2 {
  color: $body-color;
  font-size: $font-size-h2;
  font-weight: $font-weight-normal;
  line-height: $line-height-h2;
}

.h3 {
  color: $body-color;
  font-size: $font-size-h3;
  font-weight: $font-weight-normal;
  line-height: $line-height-h3;
}

.h4 {
  color: $body-color;
  font-size: $font-size-h4;
  font-weight: $font-weight-normal;
  line-height: $line-height-h4;
}

.h5 {
  color: $body-color;
  font-size: $font-size-h5;
  font-weight: $font-weight-normal;
  line-height: $line-height-h5;
}

.h6 {
  color: $body-color;
  font-size: $font-size-h6;
  font-weight: $font-weight-normal;
  line-height: $line-height-h6;
}

p {
  color: $body-color;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.meta {
  color: $body-color;
  font-size: $font-size-sm;
  line-height: 16px;
}
