/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

.p-la-icon {
  background: none;
  border: 0 none;
  color: $medium-gray;
  padding: 0;

  &-18 {
    height: 18px;
    width: 18px;
  }

  &-24 {
    height: 24px;
    width: 24px;
  }

  svg {
    fill: $medium-gray;
  }

  &:focus {
    outline: 0 none;
    box-shadow: 0 0 0 2px $white, 0 0 0 4px $focus-outline;
  }

  &:hover {
    svg {
      fill: $primary;
    }
  }

  + .p-la-icon {
    margin-left: 8px;
  }
}

.p-la-icon-sm {
  height: 36px;
  width: 36px;
}

.p-la-icon-lg {
  height: 48px;
  width: 48px;
}

.p-la-icon-active {
  background: $stainless;
  border-radius: 2px;

  svg {
    fill: $primary;
  }
}
