/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

.p-wsol-container {
  padding: 0 12px;

  @include medium-tab {
    max-width: 704px;
  }

  @include large-tab {
    max-width: 864px;
  }

  @include small-desktop {
    max-width: 960px;
  }

  @include medium-desktop {
    max-width: 1056px;
  }

  @include large-desktop {
    max-width: 1191px;
  }
}

.p-wsol-container-fluid {
  padding: 0 12px;
}

.wsol-row {
  margin: 0 -12px;
}

[class*='col-'] {
  padding-left: 12px;
  padding-right: 12px;
}
