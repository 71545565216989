.st {
  &-item {
    border: solid 1px #c7c7c7;
    border-radius: 3px;
    max-width: 744px;
    padding: 11px 16px 14px 16px;
    background-color: #fff;
    margin-bottom: 16px;

    &-image {
      min-width: 106px;
      padding: 5px 8px;

      img {
        max-width: 90px;
        max-height: 95px;
      }

      svg {
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
        fill: #6a7070;
      }

      span {
        font-size: 18px;
        color: #252525;
      }
    }

    &-content {
      width: 100%;

      .p-la-form-group {
        margin-bottom: 0px;
      }
    }

    &-title {
      color: #252525;
      font-size: 14px;
      margin-bottom: 8px;
    }

    &-desc {
      color: #6a7070;
      font-size: 12px;
      margin-bottom: 4px;
    }
  }
}
