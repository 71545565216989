/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

body {
  background: $body-bg;
  color: $body-color;
  font-family: $font-family-text;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
}

a {
  color: $primary;
  font-size: $font-size-base;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 0 0.125rem $white, 0 0 0 0.25rem $focus-outline;
    outline: 0 none;
  }
}

hr {
  border: 0 none;
  border-top: 1px solid $concrete;
  margin: 1rem 0;
}
